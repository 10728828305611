body {
  font-family            : -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-form-item-label>label {
  overflow-wrap: break-word;
  white-space  : normal;
  font-weight  : 600;
}

.ant-input-number {
  width: 100% !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding    : 0 !important;
  font-weight: 600;
}

.ant-tabs-tab h1 {
  margin-bottom: 0;
}

th .ant-table-cell {
  padding: 4px 8px !important;
}

.ant-table-cell {
  word-wrap : break-word;
  word-break: break-word;
  padding   : 4px 8px !important;
}

.ant-table-column-sorters {
  padding: 2px 2px !important;
}

::-webkit-scrollbar {
  width : 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius   : 6px;
}